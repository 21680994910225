import React from 'react'
import ExLink from "../components/exLink";

import { graphql } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links";

import SEO from "../components/seo";
import LayoutSub from "../components/layoutSub";

import Call from "../static/svg/icon-call.inline.svg";
import Mail from "../static/svg/icon-mail.inline.svg";


function Kasai({data, pageContext, location}) {
  const { breadcrumb: { crumbs } } = pageContext;

  const {pathname} = location;
  const pagetitle = "葛西院：葛西オステオパシールーム";
  const description = "葛西駅徒歩２分の整体院・ぺんぎん堂のご案内。江戸川区・浦安地区で、肩こり・首痛・頭痛・腰痛などでお困りのかたへ。";

  const { state = {} } = location;
  const pathTop = (state) ? state.pathTop : "/";

    return (
      <>

        <LayoutSub crumbs={crumbs} pathname={pathname}  pathTop={pathTop}>
          <SEO
            pageurl={pathname}
            pagetitle={pagetitle}
            description={description}
          />

          <div>
            <h1 className="heading-b">葛西院のご案内</h1>
            <p>葛西での営業は、2024年３月末をもって終了いたしました。５年間、ご愛顧ありがとうございました。</p>
            <p>葛西での施術をご希望の方は、   <ExLink href="http://k-oste-noha.moon.bindcloud.jp/">葛西オステオパシールーム</ExLink>に直接お問い合わせください。</p>

            <hr className="my-5" />
            <p>現在は、杉並区西荻窪にて営業しております。葛西駅からは乗り換えなしで来れます&#x1f600;</p>
            <p>西荻窪へのお問い合わせも大歓迎です。</p>

            <div className="sm:pointer-events-none">
            <a href={`tel:${data.site.siteMetadata.tel}`.replace(/\s+/g, "")}>
            <p className="text-2xl text-darkteal font-semibold underline sm:no-underline my-3 ">
            <Call className="w-6 h-6 inline pb-0.5 ml-1" />
            {data.site.siteMetadata.tel}
            </p>
            </a>
            </div>

            <AnchorLink to="#form" >
            <button type='button' className="border border-emerald-600 bg-emerald-500 text-white hover-hover:hover:bg-emerald-600  text-center my-4 py-2 px-4 rounded font-semibold shadow">

            <Mail className="h-6 w-6 inline-block mr-2 align-bottom" />
            Send Us Message

            </button>
          </AnchorLink>

            </div>

            


        </LayoutSub>
      </>

      
    )
}

export default Kasai;


export const query = graphql`
query {
  site {
  siteMetadata {
  tel
}
}
}
`